<template>
    <div class="exchange container">
        <v-header deep theme></v-header>
        <div class="scroll-container">
            <div class="user-group">
                <div class="user-info">
                    <span class='hello'>Hello, {{userInfo.username}}</span>
                </div>
                <div class="image-group">
                    <avatar class="avatar" :border="false" :src="userInfo.photoResourceUrl"></avatar>
                </div>


            </div>

            <div class="exchange-code-group">
                <span class="code-title">- 会员兑换码激活 -</span>
                <input type="text" placeholder="请输入兑换码" v-model="code"/>
                <div class="btn" @click="exchange">立即兑换</div>
            </div>

            <div class="remark-group">
                <span>使用须知：</span>
                <span class="remark">
                <p>①登录帐号后，输入兑换码即可激活对应级别会员。</p>
                <p>②激活成功后，即日起成为灵感严选会员，享有对应级别会员所有权益。</p>
                <p>③此卡不支持开发票，不支持退款，请妥善保管，遗失、泄露、绑错帐号等不补发。</p>
                <p>④激活码16位，无需输入横杠，需要区分大小写。</p>
            </span>
            </div>

        </div>

        <model ref="model9" :showClose="false" :canclose="false">
            <div class="model_content">
                <div class="daojishi"><span class="txt">{{downTime}}秒</span></div>
                <img class="gou" src="https://cdn.001ppt.cn/h5/assets/images/gou.png">
                <div class="email">
                    <span class="email_txt" style="font-weight: bold;">会员兑换成功！</span>
                </div>
            </div>
        </model>

    </div>
</template>

<script>
    import {user} from '@/api'
    import {mapMutations,mapGetters} from 'vuex'
    import model from '@/components/model/model'
    import avatar from '@/components/avatar'
    const TIME = 3;
    export default {
        data() {
            return {
                code: '',
                downTime: TIME
            };
        },
        components: {model,avatar},
        computed: {...mapGetters(['userInfo'])},
        methods: {
            ...mapMutations(['SET_MEMBER']),
            async exchange() {
                if(!this.code) return this.$toast.info('请输入兑换码');
                this.$loading();
                try {
                    const {code, msg} = await user.exchange(this.code);
                    if(code != 1) return this.$toast.error(msg);
                    this.getMemberInfo();

                    this.$refs.model9.toggleDialog();

                    var timer = setInterval(_ => {
                        if(this.downTime > 1){
                            this.downTime --;
                        }else {
                            clearInterval(timer);
                            this.$refs.model9.toggleDialog();
                            this.this.downTime = TIME;
                        }
                    },1000)

                } catch (e) {
                    this.$toast.error('请求失败');
                } finally {
                    this.$loading.close();
                }

            },


            //获取会员信息
            async getMemberInfo() {
                const {data} = await user.memberInfo();
                if(!data){
                    this.SET_MEMBER({vipIdentify: 0});
                }else {
                    this.SET_MEMBER(data);
                }


            },
        }
    };
</script>

<style lang="less" scoped>
    .scroll-container {
        background-color: var(--text-color);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user-group {
        padding: 54px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .user-group .user-info {
        display: flex;
        flex-direction: column;
    }

    .user-group .user-info span {
        color: #fff;
    }

    .user-group .user-info .hello {
        font-size: 24px;
        font-weight: bold;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .user-group .user-info .desc {
        font-size: 14px;
        margin-top: 4px;
    }

    .user-group .image-group {
        width: 62px;
        height: 62px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .user-group .image-group .avatar{
        width: 52px;
        height: 52px;
        border-radius: 100%;
    }




    .exchange-code-group {
        background-color: #fff;
        width: 320px;
        height: 192px;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 19px 20px 21px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .code-title {
        font-size: 24px;
        font-weight: bold;
    }

    .exchange-code-group input {
        width: 280px;
        height: 40px;
        border-radius: 5px;
        background-color: #f3f3f5;
        padding: 0 5px;
        margin: 20px 0;
        border: none;
        outline: none;
    }

    .btn {
        width: 270px;
        height: 45px;
        background-color: var(--text-color);
        border-radius: 20px;
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .remark-group {
        display: flex;
        flex-direction: column;
        padding: 48px 48px 21px;
    }

    .remark-group span, .remark-group p {
        color: #8a8e9a;
    }

    .remark {
        padding-top: 25px;
        p {
            line-height: 200%;
        }
    }
</style>
